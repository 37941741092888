
import { state } from '@/harlem/stores'
import { useRouter } from 'vue-router'
import { proxyApi } from "@/http/http.config";
import { setStatus } from '@/services/stage.service'
import checklistMethods from "../components/Checklist/Helpers/Index";
import { encodeWithParam } from "@/util/DataEncodeDecode";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { onMounted, onUnmounted } from 'vue';
import { gTagEvent } from '@/util/GoogleAnalytics';
import InitChecklist from "../components/Checklist/InitChecklist.vue";

export default {
	name: 'kycProcessing',
	components: {
		FadeLoader,
		InitChecklist
	},
	methods: {
		isMobile() {
			return checklistMethods.isMobile();
		},
	},
	setup() {
		const router = useRouter()

		let timeout: ReturnType<typeof setTimeout>
		let baseTimeout: ReturnType<typeof setTimeout>

		const checkStatus = () => {
			proxyApi.get('/getDriverKYCstatus')
				.then(async ({data}) => {
					if (data.success && data.rowCount > 0) { 

						let kycStatus = data.result?.kyc_status;
						let failedAttepmts = data.result?.failed_attempts_count;
						let kycThreshold = data.kycthreshold;
						console.log(kycStatus,failedAttepmts)

						if(kycStatus == 'failed'){
							if(failedAttepmts < kycThreshold){
								router.push({ 
									name: 'ReviewEdit', 
								})
							}else{
								router.push({ 
									name: 'kycFail',
								})
							}
						}else if(kycStatus == 'passed'){
							sessionStorage.setItem("statusWarningMessage", "Insurance upload incomplete");
							 router.push({
									name: "ca-dashboard",
								});
						}else{
							setTimeout(checkStatus, 2000) // handling if payfare send pending or manual pending
						}
					}else{
						setTimeout(checkStatus, 2000)
					}
				})
		}

		onMounted(() => {
			timeout = setTimeout(checkStatus, 2000)
		})

		onUnmounted(() => {
			clearTimeout(timeout)
			clearTimeout(baseTimeout)
		})
	},
}
