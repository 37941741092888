
import applicationValidationGuard from "@/router/applicationValidationGuard";
import { RouteRecordRaw } from "vue-router";
import locationValidationGuard from "@/router/locationValidationGuard";
import { ref } from "vue";
import stepValidationGuard from "@/router/stepValidationGuard";

const isFirstVisit = ref(true);
export default [
  // {
  //   path: "/ca-signup",
  //   name: "ca-signup",
  //   props: true,
  //   // beforeEnter: locationValidationGuard,
  //   component: () => import("@/modules/ca/signup/Registration.vue"),
  //   meta: { title: "Delivery Drivers Inc | Login", open: true, layout: "account" },
  // },
  {
    path: "/ca-signup/personalInfo",
    name: "ca-personalInfo",
    props: true,
    component: () => import("@/modules/ca/signup/PersonalInfo.vue"),
    meta: {
      title: "Signup | Verification",
      noHeader: false,
      step: "signupStage",
      //  order: 2,
      open: true,
      layout: "dashboard",
      //   authgaurd: true
    },
  },
  // {
  //   path: "/ca-signup/fullProfileDetails",
  //   name: "fullProfileDetails",
  //   component: () => import("@/modules/ca/signup/FullProfileDetails.vue"),
  //   meta: { title: "Delivery Drivers Inc", noHeader: false, open: true, layout: "dashboard"},
  // },
  // {
  //   path: "/ca-signup/piconformation",
  //   name: "personalInfoConfirmation",
  //   props: true,
  //   component: () => import("@/modules/ca/signup/PersonalInfoConfirmation.vue"),
  //   meta: {
  //     title: "Signup | Verification",
  //     noHeader: true,
  //     step: "signupStage",
  //   //  order: 2,
  //     open: true,
  //     layout: "signup",
  //  //   authgaurd: true
  //   },
  // },
  {
    path: "/ca-signup/createpass",
    name: "CreatePassword",
    props: true,
    component: () => import("@/modules/ca/signup/CreatePassword.vue"),
    meta: {
      title: "Signup | Verification",
      noHeader: true,
      //  step: "VERIFICATION",
      //  order: 2,
      step: "signupStage",
      open: true,
      layout: "signup",
      //   authgaurd: true
    },
  },
  {
    path: "/ca-signup/retryPII",
    name: "retryPII",
    props: true,
    component: () => import("@/modules/ca/signup/RetryPII.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/retryPIILocked",
    name: "retryPIILocked",
    component: () => import("@/modules/ca/signup/RetryPIILocked.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/kycPendingManual",
    name: "kycPendingManual",
    component: () => import("@/modules/ca/signup/KycPendingManualVerification.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/kycPending",
    name: "kycPending",
    component: () => import("@/modules/ca/signup/KycPending.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/kycProcessing",
    name: "kycProcessing",
    component: () => import("@/modules/ca/signup/KycWait.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/GoogleAuto",
    name: "GoogleAuto",
    props: true,
    component: () => import("@/modules/ca/googleAuto.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/accountProcessing",
    name: "accountProcessing",
    component: () => import("@/modules/ca/signup/AccountWait.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/LocationUpdateCa",
    name: "LocationUpdateCa",
    component: () => import("@/modules/ca/driversignup/LocationUpdateCa.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/kycFail",
    name: "kycFail",
    component: () => import("@/modules/ca/signup/KycFail.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {

    path: "/ca-dashboard/dashboard",
    name: "ca-dashboard",
    props: true,
    component: () => import("@/modules/ca/dashboard/Dashboard.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/personaldd",
    name: "PersonalDirectDeposit",
    props: true,
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/signup/PersonalDirectDeposit.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" }
  },
  {

    path: "/ca-dashboard/deposite-summaries-home",
    name: "ca-deposite-summary-home",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/CADepositeSummaryHome.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {

    path: "/ca-dashboard/deposite-summaries-details",
    name: "ca-deposite-summary-details",
    component: () => import("@/modules/ca/dashboard/CADepositeSummaryDetails.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {

    path: "/ca-dashboard/deposite-summaries",
    name: "ca-deposit-summary",
    component: () => import("@/modules/ca/dashboard/CADepositeSummary.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },

  {

    path: "/ca-dashboard/earnings-account-home",
    name: "ca-earnings-account-home",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/EarningsAccountHome.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },

  {

    path: "/ca-dashboard/personal-document",
    name: "PersonalDocuments-ca",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/PersonalDocuments.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },

  {

    path: "/ca-profile",
    name: "CaProfile",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/CaProfile.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-profile/business-register",
    name: "Ca-NewBusinessRegister",
    component: () => import("@/modules/ca/dashboard/CARegisterBusiness.vue"),
    meta: { title: "Delivery Drivers Inc | Profile", noHeader: false , layout: "dashboard"},
  },

  {
    path: "/ca-dashboard/earnings-account",
    name: "ca-earnings-account",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/EarningsAccount.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-dashboard/licence-edit",
    name: "PersonalDocumentsEdit",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/EditDriversLicense.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },



  {
    path: "/ca-signup/account-locked",
    name: "accountLockedCA",
    component: () => import("@/modules/ca/signup/AccountLocked.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup/checklist",
    name: "checklist",
    props: true,
    component: () => import("@/modules/ca/signup/CheckList.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" },
  },
  {
    path: "/ca-signup",
    name: "PhoneVerification",
    props: true,

    beforeEnter: (to, from, next) => {
      if (isFirstVisit.value) {
        isFirstVisit.value = false;
        next({
          name: "LandingPage",
          query: {
            locationId: to.query.locationId,
            name: to.query.name,
          },
        });
      } else {
        next();
      }
    },
    component: () => import("@/modules/ca/driversignup/PhoneVerification.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: true, layout: "signup" },
  },
  {
    path: "/ca-signup/enroll",
    name: "LandingPage",
    props: true,
    component: () => import("@/modules/ca/driversignup/LandingPage.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: true, layout: "signup" },
  },


  {
    path: "/ca-signup/verification",
    name: "CreateAccount",
    component: () => import("@/modules/ca/driversignup/CreateAccount.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: true, layout: "signup" }
  },
  {
    path: "/error-PI",
    name: "ReviewEdit",
    component: () => import("@/modules/ca/driversignup/ReviewEdit.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" }
  },

  {
    path: "/ca-signup/uploadVehicleDetails",
    name: "UploadCardDetails",
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/driversignup/DriverInsuranceUpload.vue"),
    meta: { title: "Delivery Drivers Inc", open: true, noHeader: false, layout: "dashboard" }
  },
  {
    path: "/ca-signup/fullProfileDetails",
    name: "fullProfileDetails",
    props: true,
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/driversignup/FullProfileDetails.vue"),
    meta: { title: "Signup | Verification", noHeader: false, open: true, layout: "dashboard" },
  },

  {
    path: "/ca-signup/piconformation",
    name: "personalInfoConfirmation",
    props: true,
    component: () => import("@/modules/ca/driversignup/PersonalInfoConfirmation.vue"),
    meta: {
      title: "Signup | Verification",
      noHeader: false,
      step: "signupStage",
      //  order: 2,
      open: true,
      layout: "dashboard",
      //   authgaurd: true
    },
  },

  {
    path: "/ca-signup/enrollment-checklist",
    name: "enrollment-checklist",
    props: true,
    beforeEnter: stepValidationGuard,
    component: () => import("@/modules/ca/dashboard/EnrollmentChecklist.vue"),
    meta: { title: "Signup | Verification", noHeader: false, open: true, layout: "dashboard" },
  },

  // { 
  //   path: '/:pathMatch(.*)*',
  //   name: "pageNotFound",
  //   component: () => import("@/modules/ca/dashboard/PageNotFound.vue"),
  //   meta : {
  //     title: "Delivery Drivers Inc | Session Expired",   
  //     noHeader : true,
  //     open:true
  //   }
  // },

] as Array<RouteRecordRaw>;
